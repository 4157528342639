<template>
  <div class="main-cnt statement-container store-container">
    <el-row :gutter="10">
      <el-col :span="2">
        <el-select v-model="formData.projectId" placeholder="请选择项目">
          <el-option
            :label="item.p_name"
            :value="item.p_id"
            v-for="item in projectOptions"
            :key="item.p_id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-row>
          <el-col :span="5" class="text-center">
            <span>支付时间</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.stime"
              type="date"
              placeholder="支付时间开始"
              @change="changeStime"
            />
          </el-col>
          <el-col :span="1" class="text-center">
            <span>-</span>
          </el-col>
          <el-col :span="9">
            <el-date-picker
              :clearable="false"
              v-model="formData.etime"
              type="date"
              placeholder="支付时间结束"
              @change="changeEtime"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-select
          v-if="isTableDetail"
          v-model="queryData.storeId"
          multiple
          collapse-tags
          clearable
          placeholder="请选择门店"
        >
          <el-option
            :label="item.pom_name"
            :value="item.pom_id"
            v-for="item in storeOptions"
            :key="item.pom_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select
          v-if="isTableDetail"
          v-model="queryData.payway"
          multiple
          clearable
          collapse-tags
          placeholder="请选择支付方式"
        >
          <el-option
            :label="item.orp_name"
            :value="item.orp_id"
            v-for="item in orderTypeOptions"
            :key="item.orp_id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4"></el-col>
      <el-col :span="5" class="action-btn">
        <template v-if="showModule !== 1">
          <el-tooltip
            class="item"
            effect="dark"
            content="导出"
            placement="left"
          >
            <span class="border-icon" @click="handleExport">
              <i class="iconfont icon-a-lujing207"></i
            ></span>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="打印"
            placement="left"
          >
            <span class="border-icon mr-10" @click="handlePrint">
              <i class="iconfont icon-a-lujing206"></i
            ></span>
          </el-tooltip>
          <el-button @click="handleReset">重置</el-button>
        </template>
        <el-button
          v-if="showModule !== 1"
          type="primary"
          @click="changeModule(1)"
          >统计</el-button
        >

        <el-button
          v-if="showModule === 1"
          @click="changeModule(2)"
          class="w-120 store-query"
          >按门店查询</el-button
        >
        <el-button
          v-if="showModule === 1"
          type="primary"
          @click="changeModule(3)"
          class="w-120"
          >按商品查询</el-button
        >
      </el-col>
    </el-row>
    <div class="content">
      <StoreEcharts
        v-if="showModule === 1"
        :queryData="lastEchartQuery"
        @paywayClick="paywayClick"
        @storeClick="storeClick"
      ></StoreEcharts>

      <StoreTable
        v-if="showModule === 2"
        ref="scenicTableRef"
        :queryData="lastTableQuery"
        @showSelect="showSelect"
        :echartClick="echartClick"
      ></StoreTable>
      <StoreGoodsTable
        v-if="showModule === 3"
        ref="scenicTableRef"
        :queryData="lastTableQuery"
      ></StoreGoodsTable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive, computed } from "vue";
import { BasicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import StoreEcharts from "../components/StoreEcharts.vue";
import StoreTable from "../components/StoreTable.vue";
import StoreGoodsTable from "../components/StoreGoodsTable.vue";

import dayjs from "dayjs";
export default {
  components: {
    StoreEcharts,
    StoreTable,
    StoreGoodsTable,
  },
  setup() {
    onMounted(() => {
      getOrderType();
      getStoreData();
      getProjectData();
    });
    const showModule = ref(1); //1图 2按门店查询表格 3按商品查询表格
    const isTableDetail = ref(false);
    function showSelect(flag) {
      isTableDetail.value = flag;
    }
    const formData = reactive({
      projectId: "",
      stime: new Date(),
      etime: new Date(),
    });
    const queryData = ref({
      storeId: [],
      payway: [],
    });
    const projectOptions = ref([]);
    const getProjectData = () => {
      BasicApi.projectSelect().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            projectOptions.value = res.Data;
            if (!formData.projectId) {
              formData.projectId = projectOptions.value[0].p_id;
            }
          }
        } else {
          let msg = res.Message ? res.Message : "获取数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const orderTypeOptions = ref([]);
    const getOrderType = () => {
      BasicApi.paywayOptions().then((res) => {
        if (res.Code === 200) {
          orderTypeOptions.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取支付方式失败！";
          ElMessage.error(msg);
        }
      });
    };

    const storeOptions = ref([]);
    function getStoreData() {
      BasicApi.getAllPolyMerchantData().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            storeOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取门店数据失败！";
          ElMessage.error(msg);
        }
      });
    }

    function changeStime(data) {
      if (formData["etime"] && dayjs(formData["etime"]).isBefore(dayjs(data))) {
        //如果结束时间有值 且 在开始时间之前
        formData["stime"] = formData["etime"];
        formData["etime"] = data;
      } else {
        formData["stime"] = data;
      }
    }
    function changeEtime(data) {
      if (formData["stime"] && dayjs(formData["stime"]).isAfter(dayjs(data))) {
        formData["etime"] = formData["stime"];
        formData["stime"] = data;
      } else {
        formData["etime"] = data;
      }
    }
    function changeModule(type) {
      showSelect(false);
      echartClick.value = false;
      showModule.value = type;
      if (showModule.value === 1) {
        queryData.value = {
          storeId: [],
          payway: [],
        };
      }
    }

    function handleReset() {
      formData["stime"] = new Date();
      formData["etime"] = new Date();
      queryData.value = {
        storeId: [],
        payway: [],
      };
    } // 是否点击图表
    const echartClick = ref(false);
    function paywayClick(data) {
      echartClick.value = true;
      queryData.value.payway = [data];
      queryData.value.storeId = [];
      showModule.value = 2;
    }
    function storeClick(data) {
      echartClick.value = true;
      queryData.value.storeId = [data];
      queryData.value.payway = [];
      showModule.value = 2;
    }
    const lastTableQuery = computed(() => {
      return {
        ...queryData.value,
        stime: dayjs(formData.stime).format("YYYY-MM-DD"),
        etime: dayjs(formData.etime).format("YYYY-MM-DD"),
        projectId: formData.projectId,
      };
    });
    const lastEchartQuery = computed(() => {
      return {
        stime: dayjs(formData.stime).format("YYYY-MM-DD"),
        etime: dayjs(formData.etime).format("YYYY-MM-DD"),
        projectId: formData.projectId,
      };
    });
    const scenicTableRef = ref(null);
    function handleExport() {
      scenicTableRef.value.handleExport();
    }
    function handlePrint() {
      scenicTableRef.value.handlePrint();
    }
    return {
      changeModule,
      formData,
      projectOptions,
      changeEtime,
      changeStime,
      queryData,
      orderTypeOptions,
      paywayClick,
      storeClick,
      handleReset,
      lastTableQuery,
      showModule,
      handleExport,
      handlePrint,
      scenicTableRef,
      lastEchartQuery,
      storeOptions,
      showSelect,
      echartClick,
      isTableDetail,
    };
  },
};
</script>

<style lang="scss">
.main-cnt {
  height: auto !important;
}
.store-container {
  .w-120 {
    width: 100px !important;
  }
  .store-query {
    background-color: #60cbba !important;
    border-color: #60cbba;
    color: #fff !important;
  }
}
</style>

